import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './style.scss';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { vendorUUID } from '../../utils/constants';
import { getPasswordResetBaseURL } from '../../utils/getPasswordResetBaseURL';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import { logo } from '../../BRAND_CONFIG';
import HeaderLogo from '../../components/header/HeaderLogo';
import Loading from '../../components/loading';

export default class extends React.Component {
  state = {
      passwordResetMessage: '',
      validationMessage: '',
      email: '',
      confirmPassword: '',
      loading: false,
  };

  constructor(props) {
      super(props);
  }

  forgotPasswordClicked = () => {
      const { email } = this.state;
      if (!email) {
          this.setState({
              validationMessage: 'You must enter your email address',
          });
      } else if (email.indexOf('@') === -1 || email.indexOf('.') === -1) {
          this.setState({
              validationMessage: 'Please enter a valid email address',
          });
      } else {
          this.setState({
              loading: true,
          });
          axios({
              method: 'post',
              url: `${getPasswordResetBaseURL}/reset-account-password`,
              data: {
                  username: `${email}#${vendorUUID}`,
                  resetRedirectUri: `${window?.location.origin}/reset-password/?code=`,
              },
          })
              .then((response) => {
                  console.log('response', response);
                  this.setState({ loading: false });
                  if (response.data.errorMessage) {
                      this.setState({
                          validationMessage: response.data.errorMessage,
                      });
                  } else {
                      this.setState({
                          passwordResetMessage: `An email with instructions on how to reset your password has been sent to ${email}.`,
                      });
                  }
              })
              .catch((error) => {
                  console.log('error', error.message);
                  this.setState({ loading: false });
                  this.setState({
                      validationMessage: error.response.data.errorMessage || 'Sorry, it looks like something went wrong',
                  });
              });
      }
  };

  render() {
      const {
          passwordResetMessage, validationMessage, email, loading,
      } = this.state;
      return (
          <Layout>
              <SEO title="Reset Password" />
              <Container fluid className="page-wrapper">
                  <Row>
                      {loading && <Loading />}
                      {!loading && passwordResetMessage && <div className="password-success-reset-message">{passwordResetMessage}</div>}
                      {!loading && !passwordResetMessage && (
                          <Col>
                              <HeaderLogo title="Forgot your password?" logo={logo} logoSize={100} />
                              <p>Please enter your email below and we will email you with a link to reset your password.</p>
                              <Form className="form-signin">
                                  <Form.Group className="form-label-group mt-4">
                                      <Form.Label for="email">Email</Form.Label>
                                      <Form.Control
                                          autoFocus
                                          type="email"
                                          id="email"
                                          className="form-control"
                                          required
                                          value={email}
                                          onChange={({ target }) => {
                                              this.setState({
                                                  validationMessage: '',
                                                  email: target.value.toLowerCase().replace(' ', ''),
                                              });
                                          }}
                                      />
                                  </Form.Group>
                                  {!!validationMessage && <p className="text-danger text-center">{validationMessage}</p>}
                                  <div className="d-grid">
                                      <Button onClick={this.forgotPasswordClicked}>
                                          Reset
                                      </Button>
                                  </div>
                              </Form>
                          </Col>
                      )}
                  </Row>
              </Container>
          </Layout>
      );
  }
}
